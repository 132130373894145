<template>
	<section class="brands">
		<div class="brands__container">
			<h2 class="brands__heading">We work with</h2>
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(brand, index) in brands" :key="index">
						<!-- 你的滑块内容 -->
						<img class="brands__brand" :src="brand.src" :alt="brand.alt">
					</div>
				</div>

				<!-- 如果需要分页或导航 -->
				<div class="swiper-pagination"></div>
				<div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div>
			</div>
			<!-- <a class="button button--secondary" href="https://system1.com/what-we-do#brands">See more<span
					class="lines-y"></span><span class="lines-x"></span></a> -->
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				swiperInstance: null,
				brands: [{
						src: "/img/tiktok.png",
						alt: "Tiktok Logo"
					},
					{
						src: "/img/Taboola.png",
						alt: "Taboola Logo"
					},
					{
						src: "/img/facebook.png",
						alt: "Facebook Logo"
					},
					{
						src: "/img/googleads.png",
						alt: "GoogleAds Logo"
					},
					{
						src: "/img/bingads.png",
						alt: "BingAds Logo"
					},
					{
						src: "/img/Ads.png",
						alt: "Ads Logo"
					},
					{
						src: "/img/Tonic.png",
						alt: "Tonic Logo"
					},
					{
						src: "/img/System1.png",
						alt: "System1 Logo"
					}
				]
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.initSwiper()
			})
		},
		methods: {
			initSwiper() {
				this.swiperInstance = new window.Swiper('.swiper-container', {
					slidesPerView: 1,
					spaceBetween: 50,
					breakpoints: {
						1600: {
							slidesPerView: 5,
							slidesPerGroup: 5
						},
						768: {
							slidesPerView: 3,
							slidesPerGroup: 3
						},
						0: {
							slidesPerView: 1,
							slidesPerGroup: 1
						},
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					keyboard: {
						enabled: true,
					}
				})
			}
		}
	}
</script>

<style>
	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 200px;
		/* 可根据需要调整高度 */
	}

	.swiper-button-next,
	.swiper-button-prev {
		color: rgba(0, 0, 0, 0);
		cursor: pointer;
	}
</style>