<template>
	<section class="home-ramp">
		<div class="home-ramp__container">
			<div class="home-ramp__content">
				<h2 class="home-ramp__heading"> {{title}}</h2>
				<p class="home-ramp__lead">{{description}}</p>
				<a v-show="false" class="home-ramp__button button" href="/what-we-do">Learn more<span
						class="lines-y"></span><span class="lines-x"></span></a>
			</div>
			<div class="home-ramp__image"></div>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			},
			description: {
				type: String,
				default: ''
			}
		}
	}
</script>

<style>
</style>