<template>
	<section class="home-banner">
		<div class="home-banner__container">
			<div class="home-banner__content">
				<h1 class="home-banner__heading">
					<span class="home-banner__logo">THINK</span> {{ title }}
				</h1>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			}
		}
			
	}
</script>

<style>
</style>