import HomeBanner from './home-banner'
import HomeRamp from './home-ramp'
import HomeWho from './home-who'
import HomeBrands from './home-brands'
import HomeJoin from './home-join'
import HomeSlogan from './home-slogan'
export default {
	HomeBanner,
	HomeRamp,
	HomeWho,
	HomeBrands,
	HomeJoin,
	HomeSlogan
}