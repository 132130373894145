<template>
	<section class="who">
		<div class="who__container">
			<div class="who__content">
				<h2 class="who__heading">{{title}}</h2>
				<p class="who__lead">{{description}}</p>
				<a v-if="false" class="who__button button button--secondary" href="/who-we-are">Who we
					are<span class="lines-y"></span><span class="lines-x"></span></a>
			</div>
			<div v-show="false" class="who__image"></div>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			},
			description: {
				type: String,
				default: ''
			}
		}
	}
</script>

<style>
</style>