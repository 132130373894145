<template>
	<div>
		<home-banner :title="bannerTitle"></home-banner>
		<home-ramp :title="ramp.title" :description="ramp.description"></home-ramp>
		<home-slogan></home-slogan>
		<home-who :title="who.title" :description="who.description"></home-who>
		<home-brands></home-brands>
		<home-join></home-join>
	</div>
</template>

<script>
	import Components from '@/components/index'
	export default {
		components: {
			...Components
		},
		data() {
			return {
				bannerTitle: 'Online Traffic Trade with Automatic system',
				ramp: {
					title: 'We are a technology driven startup',
					description: 'At Think & Innovation, we specialize in optimizing traffic buying and selling through our proprietary advertising system. Our innovative approach allows us to significantly enhance ad cost efficiency across CPC, CPS, and CPA models. With extensive experience in e-commerce, social media, and gaming, we have successfully invested over 100 million USD in advertising! Our commitment to driving results ensures that our clients achieve the best possible returns on their marketing investments.'
				},
				who: {
					title: 'About T&I Product',
					description: 'Our advanced advertising system seamlessly integrates upstream and downstream data with various ad platforms, enabling a comprehensive connection between ADs revenue and costs,which allow us to get accurate tracking of advertising performance. Leveraging real-time insights, our software automates Ads adjustments, including budget optimization, bid management, and AI-generated creative assets. Additionally, it features a robust alert system that notifies users of any anomalies at different levels, ensuring that revenue generation can be fully automated, even in unattended scenarios. Experience effortless management and maximized profitability with our innovative solution.'
				}
			}
		}
	}
</script>

<style>
</style>